/* FAQ CSS */

.ml-1 {
  margin-left: 10px !important;
}

.ml-2 {
  margin-left: 20px !important;
}

.ml-3 {
  margin-left: 30px !important;
}

.mt-1 {
  margin-top: 10px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mt-3 {
  margin-top: 30px !important;
}

.bgt-blue {
  background-color: #2d5ad4;
}

.bgt-red {
  background-color: #f74545;
}

.fr {
  float: right;
}

.fs-8 {
  font-size: 8px;
}

// Code editor btn
.sc-bczRLJ {
  border: 0px !important;
}

.sc-gsnTZi {
  padding: 20px !important;
}
