/* Blog CSS */

.blog-card {
    position: relative;
    z-index: 1;
    overflow: hidden;

    .image-wrap {
        position: relative;
        z-index: 1;
        overflow: hidden;
        border-radius: .5rem;

        img {
            transition-duration: 500ms;
        }

        .video-play-btn {
            position: absolute;
            right: 1rem;
            bottom: 1rem;
            z-index: 10;
            width: 48px;
            height: 48px;
            color: $white;
            border-radius: 50%;
            text-align: center;
            font-size: 2rem;
            background-color: $primary;
            display: none;

            &.true {
                display: block;
            }

            i {
                line-height: 48px;
            }

            &:hover,
            &:focus {
                background-color: $primary;
                color: $white;
            }
        }
    }

    .post-title {
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.3;

        @media #{$breakpoint-xl} {
            font-size: 1.5rem;
        }
    }

    .post-author img {
        width: 30px;
        border-radius: 50%;
        border: 2px solid $border;
    }

    &:hover,
    &:focus {
        .image-wrap {
            > a img {
                transform: scale3d(1.15, 1.15, 1);
            }
        }
    }
}

.news2 {
    .blog-card {
        border-radius: 0;
        box-shadow: none;

        .post-title {
            &:hover,
            &:focus {
                color: $primary;
            }
        }
    }
}

.pagination-area {

    .page-item {
        .page-link {
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            line-height: 28px;
            text-align: center;
            padding: 0;
            margin: 0 .25rem;
            font-size: 14px;
            font-weight: 700;

            &:hover,
            &:focus {
                box-shadow: none;
            }
        }

        &.active {
            .page-link {
                background-color: $primary;
                border-color: $primary;
            }
        }
    }
}

.single-widget-area {
    position: relative;
    z-index: 1;

    .widget-form {
        position: relative;
        z-index: 1;

        input {
            width: 100%;
            height: 50px;
            border: 1px solid $border;
            border-radius: 0;
            padding: 0 20px;
            border-radius: 6px;

            &:focus {
                border-color: $border;
                box-shadow: none;
            }
        }

        button {
            position: absolute;
            transition-duration: 500ms;
            width: 80px;
            height: 50px;
            background-color: $primary;
            color: $white;
            border: none;
            top: 0;
            right: 0;
            z-index: 10;
            cursor: pointer;
            border-radius: 0 6px 6px 0;

            &:hover,
            &:focus {
                background-color: $heading;
                color: $white;
            }
        }
    }

    .catagories-list {
        position: relative;
        z-index: 1;

        li {
            a {
                font-size: 1rem;
                display: block;
                margin-bottom: 1rem;
                font-weight: 700;

                i {
                    padding-right: 10px;
                }

                &:hover,
                &:focus {
                    color: $primary;
                }
            }

            &:last-child {
                a {
                    margin-bottom: 0;
                }
            }
        }
    }

    .popular-tags {
        position: relative;
        z-index: 1;
        margin-left: -3px;

        li {
            display: inline-block;
            margin: 3px;

            a {
                font-size: 14px;
                display: inline-block;
                padding: 4px 12px;
                border: 1px solid $border;
                text-transform: lowercase;
                text-align: center;
                font-weight: 700;
                color: $text;
                border-radius: 4px;

                &:hover,
                &:focus {
                    color: $white;
                    background-color: $primary;
                    border-color: $primary;
                }
            }
        }
    }

    .single-recent-post {
        position: relative;
        z-index: 1;
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }

        .post-thumb {
            flex: 0 0 100px;
            max-width: 100px;
            width: 100px;
            margin-right: 20px;
        }

        .post-content {
            .post-title {
                font-size: 1rem;
                display: block;
                margin-bottom: 5px;
                line-height: 1.5;
                font-weight: 700;

                &:hover,
                &:focus {
                    color: $primary;
                }
            }

            .post-date {
                font-size: .875rem;
                text-transform: capitalize;
                color: $text;
                margin-bottom: 0;
            }
        }
    }
}

.comment-wrapper {
    padding-bottom: 10px;
    border-bottom: 1px solid $border;

    .comment-content {
        margin-bottom: 48px;

        .comment-author {
            flex: 0 0 40px;
            width: 40px;
            max-width: 40px;
            margin-right: 15px;
            border-radius: 50%;
            height: 40px;
            border: 2px solid $border;

            img {
                border-radius: 50%;
            }
        }

        .comment-meta {
            border-left: 2px solid $border;
            padding-left: 15px;

            .post-date {
                color: $text;
                font-size: 12px;
                margin-bottom: 5px;
                pointer-events: none;
            }

            p {
                font-size: 14px;

                @media #{$breakpoint-lg} {
                    font-size: 1rem;
                }
            }

            a.like,
            a.reply {
                display: inline-block;
                padding: 5px 16px;
                font-size: 12px;
                font-weight: 700;
                text-transform: capitalize;
                border-radius: 30px;
                background-color: $gray;
                color: $heading;
                margin-right: 0.5rem;

                &:focus,
                &:hover {
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }

    .children {
        margin-left: 50px;

        .single-comment {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.post-like {
    margin-top: 1rem;
    margin-bottom: 1rem;

    span {
        font-size: 14px;
    }

    a {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: $gray;
        text-align: center;
        font-size: 20px;
        color: $danger;
        display: block;
        margin: 0 auto 1rem;

        i {
            line-height: 46px;
        }

        &:hover,
        &:focus {
            background-color: $danger;
            border-color: $danger;
            color: $white;
        }
    }
}