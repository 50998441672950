// Fonts
$fonts: 'Manrope', sans-serif;

// :: 2.0 Template Colors

// Primary
$primary: #0d6efd;

// White
$white: #ffffff;

// Secondary
$secondary: #6c757d;

// Dark
$dark: #0c0c0c;

// Heading
$heading: #1f0757;
// $heading: #2d5ad4;

// Text
$text: #8480ae;

// Text Graphite
$text-graphite: #4f5a66;

// Text Gray
$text-gray: #c2d4f8;

// Border
$border: #e9e9e9;

// Gray
$gray: #f1f4fd;

// Dark Gray
$dark-gray: #636363;

// Success
$success: #198754;

// Warning
$warning: #ffc107;

// Info
$info: #0dcaf0;

// Danger
$danger: #dc3545;

// Theme Blue
$text-blue: #1647cc;

// Theme Red
$text-red: #f74545;
