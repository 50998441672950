/* Feature CSS */

.feature-card {
  position: relative;
  z-index: 1;
  background-color: $white;
  transition-duration: 500ms;
  overflow: hidden;

  &::after {
    transition-duration: 1000ms;
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: $white;
    opacity: 0.1;
    z-index: -1;
    content: '';
    bottom: -120px;
    right: -120px;
  }

  i {
    transition-duration: 500ms;
    flex: 0 0 45px;
    min-width: 45px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: $heading;
    text-align: center;
    color: $white;
    line-height: 45px;
    font-size: 20px;
    margin-right: 15px;
  }

  h5 {
    color: $white;
  }

  h6 {
    margin-bottom: 0;
    transition-duration: 500ms;
  }

  span {
    transition-duration: 500ms;
    font-size: 14px;
  }

  &.active,
  &:hover,
  &:focus {
    border-color: $heading;
    background-color: $heading;
    box-shadow: 0 18px 56px rgba(255, 255, 255, 0.175);

    &::after {
      bottom: -50px;
      right: -40px;
    }

    i {
      box-shadow: 0 2px 38px rgba(255, 255, 255, 0.4);
    }

    h6,
    span {
      color: $white;
    }
  }
}

.feature2-card {
  border: 0;

  .card-body {
    padding: 2rem;

    .feature-icon {
      font-size: 2rem;
    }
  }
}

.feature-icon-usecase {
  margin-top: -4px;
  padding-right: 10px;
  font-size: 1.8rem;
}

.feature3-card {
  .icons-wrap {
    position: relative;
    z-index: 1;
    margin-bottom: 2rem;
    display: block;

    .first-icon {
      font-size: 3rem;
      color: $heading;
    }

    .second-icon {
      font-size: 2.5rem;
      position: absolute;
      top: 20px;
      left: 55%;
      transform: translateX(-50%);
      z-index: -9;
      color: $text-gray;
    }
  }
}

.feature--text {
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      flex: 0 0 50%;
      width: 50%;
      max-width: 50%;
      margin-bottom: 0.75rem;

      > i {
        width: 1.5rem;
        height: 1.5rem;
        background-color: $text-gray;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        color: $heading;
        line-height: 1.5rem;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.feature-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: 50px;

  @media #{$breakpoint-lg} {
    margin-top: 0px;
  }
  .background-shape {
    position: absolute;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.09);
    transform: rotate(-30deg);
    top: -300px;
    left: -250px;
    z-index: -5;

    @media #{$breakpoint-lg} {
      width: 1200px;
      height: 1200px;
      top: -600px;
      left: -500px;
    }
  }

  &.feature2 {
    // background-color: #FCE1B5;
    background-color: #c1caf5;
  }
}
