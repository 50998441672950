/* Hero CSS */

.background-animation {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 70%;
  height: 100%;

  .item2 {
    position: absolute;
    border-radius: 50%;
    width: 240px;
    height: 240px;
    top: 50%;
    left: 60%;
    z-index: -1;
    animation: rotateAnimation linear 46s infinite;

    &::after {
      position: absolute;
      content: '\f588';
      top: 10%;
      left: 10%;
      z-index: -1;
      font-family: bootstrap-icons !important;
      color: rgba(255, 255, 255, 0.3);
      font-size: 1rem;
    }
  }

  .item3 {
    position: absolute;
    border-radius: 50%;
    animation: rotateAnimation linear 40s infinite;
    width: 20%;
    height: 20%;
    left: 30%;
    top: 50%;
    z-index: -1;

    &::after {
      position: absolute;
      content: '';
      z-index: -1;
      font-family: bootstrap-icons !important;
      content: '\f588';
      font-size: 1rem;
      color: $white;
    }
  }

  .item4 {
    position: absolute;
    border-radius: 50%;
    animation: rotateAnimation linear 34s infinite;
    width: 180px;
    height: 180px;
    left: 10%;
    top: 10%;
    z-index: -1;

    &::after {
      font-size: 1rem;
      position: absolute;
      content: '\f28a';
      font-family: bootstrap-icons !important;
      top: 10%;
      left: 10%;
      z-index: -1;
      color: rgba(255, 255, 255, 0.3);
    }
  }

  .item5 {
    position: absolute;
    border-radius: 50%;
    animation: rotateAnimation linear 37s infinite;
    width: 15%;
    height: 18%;
    left: 7%;
    top: 70%;
    z-index: -1;

    &::after {
      width: 18px;
      height: 18px;
      position: absolute;
      content: '';
      z-index: -1;
      background-color: transparent;
      border-radius: 6px;
      border: 3px solid rgba(255, 255, 255, 0.3);
    }
  }
}

@keyframes rotateAnimation {
  100% {
    transform: rotate(360deg);
  }
}

.background-shape {
  .circle1 {
    width: 1100px;
    height: 1100px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    z-index: -30;
    top: -550px;
    right: -550px;

    @media #{$breakpoint-lg} {
      width: 1500px;
      height: 1500px;
      top: -750px;
      right: -750px;
    }

    @media #{$breakpoint-xxl} {
      width: 2200px;
      height: 2200px;
      top: -1100px;
      right: -1100px;
    }
  }

  .circle2 {
    width: 850px;
    height: 850px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    z-index: -20;
    top: -425px;
    right: -425px;

    @media #{$breakpoint-lg} {
      width: 1200px;
      height: 1200px;
      top: -600px;
      right: -600px;
    }

    @media #{$breakpoint-xxl} {
      width: 1700px;
      height: 1700px;
      top: -850px;
      right: -850px;
    }
  }

  .circle3 {
    width: 600px;
    height: 600px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    z-index: -10;
    top: -300px;
    right: -300px;

    @media #{$breakpoint-lg} {
      width: 900px;
      height: 900px;
      top: -450px;
      right: -450px;
    }

    @media #{$breakpoint-xxl} {
      width: 1200px;
      height: 1200px;
      top: -600px;
      right: -600px;
    }
  }

  .circle4 {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    z-index: -10;
    top: -175px;
    right: -175px;

    @media #{$breakpoint-lg} {
      width: 600px;
      height: 600px;
      top: -300px;
      right: -300px;
    }

    @media #{$breakpoint-xxl} {
      width: 700px;
      height: 700px;
      top: -350px;
      right: -350px;
    }
  }
}

.welcome-area {
  position: relative;
  z-index: 10;
  height: 800px;
  background-color: $primary;
  overflow: hidden;

  @media #{$breakpoint-md} {
    height: 500px;
  }

  @media #{$breakpoint-lg} {
    height: 660px;
  }

  @media #{$breakpoint-xl} {
    height: 800px;
  }

  @media #{$breakpoint-xxl} {
    height: 800px;
  }

  .background-image {
    position: absolute !important;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .hero-background-shape {
    position: absolute !important;
    top: -1px;
    right: -1px;
    z-index: -1;
  }

  .welcome-content {
    margin-top: 100px;

    @media #{$breakpoint-lg} {
      margin-top: 80px;
    }

    ul {
      padding-left: 0;

      li {
        position: relative;
        list-style: none;
        padding-right: 21px;
        color: $white;
        font-weight: 700;

        &::after {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          content: '\f309';
          font-family: bootstrap-icons !important;
          z-index: 1;
          font-size: 20px;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }

    h2 {
      font-size: 2rem;
      margin-bottom: 1rem;
      font-weight: 600;
      color: $white;
      letter-spacing: -1px;

      @media #{$breakpoint-lg} {
        font-size: 2.75rem;
      }

      @media #{$breakpoint-xl} {
        font-size: 3.75rem;
        margin-bottom: 1.5rem;
      }
    }

    p {
      font-size: 1rem;
      color: $white;

      @media #{$breakpoint-lg} {
        font-size: 1.25rem;
      }
    }
  }

  .welcome-actions {
    margin-top: 50px;
    display: flex;

    @media #{$breakpoint-lg} {
      margin-top: 80px;
    }
  }

  .main-api-docs-btn {
    margin-bottom: 50px;
    margin-top: -10px;
    color: $text-blue;
  }

  .welcome-thumb {
    @media #{$breakpoint-md} {
      margin-top: 50px;
    }

    @media #{$breakpoint-lg} {
      margin-top: 80px;
    }
  }

  .code-editor-front-div {
    @media #{$breakpoint-md} {
      margin-top: 500px;
      margin-left: 20px;
      width: 550px;
    }

    @media #{$breakpoint-lg} {
      margin-top: 80px;
    }
  }

  .code-editor-front {
    scrollbar-color: red yellow;
    overflow: auto;
    max-height: 300px;

    @media #{$breakpoint-md} {
      width: 550px;
      max-height: 400px;
    }

    @media #{$breakpoint-lg} {
    }
  }
  .lookup-search-input {
    font-weight: 600;
    font-size: medium;
    padding-left: 30px;
  }
  .lookup-search-btn {
    margin-left: -70px;
  }
  &.hero2 {
    .hero2-big-circle {
      width: 240px;
      height: 240px;
      border-radius: 50%;
      border: 3rem solid $gray;
      position: absolute !important;
      left: -120px;
      top: 54%;
      z-index: -1;
    }

    .welcome-content {
      h1 {
        font-size: 2rem;
        color: $heading;

        @media #{$breakpoint-lg} {
          font-size: 2.75rem;
        }

        @media #{$breakpoint-xl} {
          font-size: 2.8rem;
        }
      }

      p {
        color: $text;
      }
    }

    .background-animation {
      .item4 {
        &::after {
          border: 4px solid rgba(12, 82, 255, 0.13);
        }
      }

      .item5 {
        &::after {
          background-color: rgba(12, 82, 255, 0.11);
        }
      }
    }
  }

  &.hero3 {
    .welcome3-slide-item {
      width: 100%;
      height: 750px;

      @media #{$breakpoint-md} {
        height: 500px;
      }

      @media #{$breakpoint-lg} {
        height: 660px;
      }

      @media #{$breakpoint-xl} {
        height: 750px;
      }

      @media #{$breakpoint-xxl} {
        height: 800px;
      }
    }

    .welcome-content {
      h2 {
        font-size: 1.75rem;

        @media #{$breakpoint-lg} {
          font-size: 2.125rem;
        }

        @media #{$breakpoint-xl} {
          font-size: 3rem;
        }
      }
    }
  }

  &.hero4 {
    background-color: $white;

    .hero4-bg-shape {
      position: absolute;
      top: -1px;
      left: -1px;
      z-index: -10;
    }

    .hero4-bg-shape2 {
      position: absolute;
      top: -1px;
      right: -1px;
      z-index: -10;
    }

    .welcome-content {
      h2 {
        color: $heading;
        font-size: 1.75rem;
        margin-bottom: 1rem;

        @media #{$breakpoint-lg} {
          font-size: 2.25rem;
        }

        @media #{$breakpoint-xl} {
          font-size: 3rem;
        }
      }

      p {
        color: $text;
      }
    }

    .key-quote {
      font-size: 14px;
      padding: 0.5rem 1rem;
      background-color: $warning;
      display: inline-block;
      margin-bottom: 1rem;
      border-radius: 0.5rem;
      color: $heading;
    }

    .hero-video-card {
      position: relative;
      z-index: 1;
      border: 0;
      box-shadow: 0 16px 48px 12px rgba(12, 82, 255, 0.17);
      border-radius: 1rem;
      background-color: transparent;

      .video-shape {
        position: absolute;
        bottom: -35px;
        left: -70px;
        z-index: -1;
        animation: slideAnimation linear 12s infinite;

        @media #{$breakpoint-lg} {
          bottom: -70px;
        }
      }
    }

    .hero4-slide-controls {
      .tns-nav {
        margin-bottom: 1.5rem;
        margin-left: 0.5rem;
        line-height: 1;

        button {
          width: 0.5rem;
          height: 0.5rem;
          background-color: $text-gray;
          border: 0;
          outline: none;
          margin-right: 0.5rem;
          border-radius: 50%;

          &.tns-nav-active {
            background-color: $primary;
          }
        }
      }
    }
  }
}

@-webkit-keyframes slideAnimation {
  50% {
    bottom: 0;
  }
}

@keyframes slideAnimation {
  50% {
    bottom: 0;
  }
}
